import {
  initKioskModeSockets,
  initKioskModePreferencesChannel,
  initKioskModePassSockets,
  destroyKioskModePassSockets
} from "@/events/providers/websocket/kiosk"

const kioskEventHandlers = {
  websocket: {
    initKioskEvents: initKioskModeSockets,
    initKioskPreferencesEvents: initKioskModePreferencesChannel,
    initKioskPassEvents: initKioskModePassSockets,
    destroyKioskPassEvents: destroyKioskModePassSockets
  }
}

const realtimeEventsProvider = process.env.VUE_APP_REALTIME_EVENTS_PROVIDER

const kioskMode = {
  initKioskEvents() {
    kioskEventHandlers[realtimeEventsProvider].initKioskEvents()
  },
  initKioskPreferencesEvents() {
    kioskEventHandlers[realtimeEventsProvider].initKioskPreferencesEvents()
  },
  initKioskPassEvents(activePassId) {
    kioskEventHandlers[realtimeEventsProvider].initKioskPassEvents(activePassId)
  },
  destroyKioskPassEvents() {
    kioskEventHandlers[realtimeEventsProvider].destroyKioskPassEvents()
  }
}

export default kioskMode
